<template>
  <v-row>
    <v-col cols="12" sm="2">
      <v-card dark color="accent" outlined class="pa-2" height="100%">
        <v-card-text>
          <v-row>
            <v-select
              :items="canalOptions"
              v-model="canalSelected"
              item-text="Nombre"
              item-value="CanalId"
              append-icon="keyboard_arrow_down"
              label="Canal"
              hint="Canal"
              persistent-hint
              hide-details
            ></v-select>
          </v-row>
          <br />
          <v-row>
            <v-select
              :items="periodoOptions"
              v-model="periodoSelected"
              item-text="NombreMes"
              item-value="FechaFin"
              append-icon="keyboard_arrow_down"
              label="Periodo"
              hint="Periodo"
              persistent-hint
              hide-details
            ></v-select>
          </v-row>
          <br />
          <v-row>
            <v-radio-group v-model="tipoProductoSelected">
              <template v-slot:label>
                <div>Tipo producto</div>
              </template>
              <v-radio
                v-for="item in tipoProductoOptions"
                :key="item.Value"
                :label="item.DisplayText"
                :value="item.Value"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <v-row>
            <v-autocomplete
              :items="asociadoOptions"
              v-model="asociadoSelected"
              item-text="Nombre"
              item-value="AsociadoId"
              placeholder="Asociado"
              append-icon="keyboard_arrow_down"
              hint="Asociado"
              persistent-hint
              clearable
            ></v-autocomplete>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="10">
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="processing"
      ></v-progress-linear>
      <v-tabs>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab>Gráfica</v-tab>
        <v-tab>Reporte</v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <div ref="amchart" style="height: 45vh"></div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="chartData"
                :items-per-page="10"
              >
                <template v-slot:item.Asociado="{ item }">{{
                  item.Asociado | numeric
                }}</template>
                <template v-slot:item.Canal="{ item }">{{
                  item.Canal | numeric
                }}</template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-alert dense type="warning" text v-show="!processing && isChartEmpty"
        >No existe información para esta gráfica</v-alert
      >
    </v-col>
  </v-row>
</template>

<script>
import apiDashboard from "@/api/dashboards";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themesAnimated);

export default {
  props: ["tipoProductoOptions"],
  data() {
    return {
      processing: false,
      chartData: [],
      chart: null,
      canalSelected: null,
      periodoSelected: null,
      tipoProductoSelected: null,
      asociadoSelected: null,
      headers: [
        { text: "PERIDO", value: "Periodo" },
        { text: "ASOCIADO", value: "Asociado" },
        { text: "CANAL", value: "Canal" },
      ],
    };
  },
  computed: {
    isChartEmpty() {
      return this.chartData.length === 0;
    },
    periodoOptions() {
      return this.$store.getters.periodos;
    },
    asociadoOptions() {
      return this.$store.getters.asociados.filter(
        (c) => c.CanalId == this.canalSelected
      );
    },
    canalOptions() {
      return this.$store.getters.canalesExternos;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.XYChart);
        chart.colors.step = 4;
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";

        let data = [];
        var periodo = [];
        dataParam.forEach(function (row) {
          if (periodo.indexOf(row.Periodo) === -1) {
            periodo.push(row.Periodo);
          }
        });

        periodo.forEach(function (d) {
          let rows = dataParam.filter((c) => c.Periodo === d);
          let newRow = {
            Periodo: d,
            Canal: 0,
            Asociado: 0,
          };
          let montoAsociado = 0;
          let montoCanal = 0;

          rows.forEach(function (c) {
            if (c) {
              if (c.CodAsociado === "DAC") {
                montoCanal = (c.Monto / c.Conteo).toFixed(2);
              } else {
                montoAsociado = (c.Monto / c.Conteo).toFixed(2);
              }
            }
          });
          newRow["Canal"] = montoCanal;
          newRow["Asociado"] = montoAsociado;
          data.push(newRow);
        });

        chart.data = data;
        this.chartData = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Periodo";
        categoryAxis.title.text = "Periodo";
        categoryAxis.renderer.minGridDistance = 20;
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "Periodo";
        lineSeries.dataFields.valueY = "Asociado";
        lineSeries.name = "Socio selecionado";
        lineSeries.strokeWidth = 3;
        var bullet = lineSeries.bullets.push(new am4charts.Bullet());
        var square = bullet.createChild(am4core.Rectangle);
        square.width = 10;
        square.height = 10;
        square.horizontalCenter = "middle";
        square.verticalCenter = "middle";
        square.stroke = am4core.color("#fff");
        square.tooltipText = "{name}: [bold]{valueY}[/]";

        let lineSeriesCanal = chart.series.push(new am4charts.LineSeries());
        lineSeriesCanal.dataFields.categoryX = "Periodo";
        lineSeriesCanal.dataFields.valueY = "Canal";
        lineSeriesCanal.name = "Canal";
        lineSeriesCanal.strokeWidth = 3;
        var bulletCanal = lineSeriesCanal.bullets.push(new am4charts.Bullet());
        var squareCanal = bulletCanal.createChild(am4core.Rectangle);
        squareCanal.width = 10;
        squareCanal.height = 10;
        squareCanal.horizontalCenter = "middle";
        squareCanal.verticalCenter = "middle";
        squareCanal.stroke = am4core.color("#fff");
        squareCanal.tooltipText = "{name}: [bold]{valueY}[/]";

        this.chart = chart;
      } catch (e) {
        // empty
      }
    },
    refresh() {

      if (this.periodoSelected && this.tipoProductoSelected) {
        this.processing = true;
        let param = {
          FechaFin: this.periodoSelected,
          TipoProducto: this.tipoProductoSelected,
          AsociadoId: this.asociadoSelected,
          CanalID: this.canalSelected,
        };

        apiDashboard
          .getChartEvolucionPagoComisionesNeta(param)
          .then((response) => {
            this.generateChart(response.data);
            this.processing = false;
          })
          .catch((error) => {
            this.setError(error);
            this.processing = false;
          });
      }
    },
  },
  watch: {
    canalSelected: function () {
      this.refresh();
    },
    periodoSelected: function () {
      this.refresh();
    },
    tipoProductoSelected: function () {
      this.refresh();
    },
    asociadoSelected: function () {
      this.refresh();
    },
    canalOptions: function () {
      if (this.canalOptions.length > 0) {
        this.canalSelected = this.canalOptions[0].CanalId;
      }
    },
    periodoOptions: function () {
      if (this.periodoOptions.length > 0) {
        this.periodoSelected = this.periodoOptions[0].FechaFin;
      }
    },
    tipoProductoOptions: function () {
      if (this.tipoProductoOptions.length > 0) {
        this.tipoProductoSelected = this.tipoProductoOptions[0].Value;
      }
    },
  },
  mounted() {
    if (this.canalOptions.length > 0) {
      this.canalSelected = this.canalOptions[0].CanalId;
    }
    if (this.periodoOptions.length > 0) {
      this.periodoSelected = this.periodoOptions[0].FechaFin;
    }
    if (this.tipoProductoOptions.length > 0) {
      this.tipoProductoSelected = this.tipoProductoOptions[0].Value;
    }
    this.refresh();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>